import { forwardRef, Fragment, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3Icon, EllipsisVerticalIcon, Squares2X2Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/solid";

import { Product } from "../../data/models/partials/Product";
import { productName } from "../../formatters/billing";
import { useSlideOver } from "../../providers/SlideOverProvider";
import { classNames } from "../../utils";
import LogoSquare from "../brand/LogoSquare";
import ProductLogo from "../brand/ProductLogo";
import Button from "../ui/Button";
import ButtonLink from "../ui/ButtonLink";
import Dropdown from "../ui/Dropdown";
import Link, { LinkToParam } from "../ui/Link";

import { startCreateTeamFlow } from "./partials/application/flows/NewTeam";
import Gamification from "./partials/application/sidebar-prompts/Gamification";

import { HelloscreenGame } from "~core/gamification/helloscreen/userGamification";

type NavItem = {
  id: string;
  name: string;
  to: LinkToParam;
  icon?: React.ComponentType<any>;
  current: boolean;
  count?: number;
  countColor?: "red";
};

type Props = {
  currentTitle?: string;
  children: React.ReactNode;
  product?: Product;
  primaryNavigation: {
    items: NavItem[];
  };
  secondaryNavigation?: {
    title: string;
    items: NavItem[];
  };
  auth: {
    email: string;
  };
  gamification?: HelloscreenGame | null;
  availableProducts?: Set<Product>;
  hiddenSidebar?: boolean;
  fixedHeight?: boolean;
  topBar?: {
    content: string;
    cta?: {
      label: string;
    } & ({ onClick: () => void } | { to: LinkToParam });
  };
  onLogout: () => void;
};

export default function ApplicationLayout(props: Props) {
  const [desktopSidebarOpen, setDesktopSidebarOpen] = useState(false);
  const { pathname, search } = useLocation();
  const { addSlideOver } = useSlideOver();

  useEffect(() => {
    setDesktopSidebarOpen(false);
  }, [pathname]);

  // TODO: Can be removed after the old layout is gone
  useEffect(() => {
    if (search.includes("new_team")) {
      startCreateTeamFlow(addSlideOver);
      history.pushState({}, "", pathname);
    }
  }, [search, pathname, addSlideOver]);

  return (
    <>
      <div
        className={classNames(
          "bg-gray-50 dark:bg-gray-900 flex flex-col",
          props.fixedHeight ? "h-screen" : "min-h-screen",
        )}
      >
        {props.topBar && <TopBar {...props} topBar={props.topBar} />}
        <MobileNavigation {...props} />
        <DesktopNavigation
          {...props}
          desktopSideBarOpen={desktopSidebarOpen}
          setDesktopSidebarOpen={setDesktopSidebarOpen}
        />
        <main
          className={classNames(
            `divide-y divide-gray-200 dark:divide-gray-800 lg:transition-all ease-linear duration-300 relative flex-1 flex flex-col`,
            props.hiddenSidebar ? "lg:ml-10" : "lg:ml-72",
          )}
        >
          {props.children}
        </main>
      </div>
    </>
  );
}

function TopBar(props: Props & { topBar: Exclude<Props["topBar"], undefined> }) {
  return (
    <div
      className="flex min-h-12 flex-col md:flex-row text-center md:text-left md:items-center md:justify-between gap-2 bg-primary-500
        dark:bg-primary-200 p-2 md:px-4 sm:px-3.5 overflow-auto z-60 md:sticky top-0"
    >
      <p className="text-sm md:leading-6 text-white dark:text-black font-semibold flex-1">{props.topBar.content}</p>
      {props.topBar.cta &&
        ("to" in props.topBar.cta ? (
          <ButtonLink size="sm" to={props.topBar.cta.to}>
            {props.topBar.cta.label}
          </ButtonLink>
        ) : (
          <Button size="sm" onClick={props.topBar.cta.onClick}>
            {props.topBar.cta.label}
          </Button>
        ))}
    </div>
  );
}

function MobileNavigation(props: Props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();
  const { addSlideOver } = useSlideOver();

  useEffect(() => {
    setSidebarOpen(false);
  }, [pathname]);

  const currentPageName = useMemo(() => {
    if (props.currentTitle) return props.currentTitle;

    const primaryName = props.primaryNavigation.items.filter((i) => i.current)[0]?.name;
    const secondaryName = props.secondaryNavigation?.items.filter((i) => i.current)[0]?.name;

    return secondaryName ? `${primaryName} » ${secondaryName}` : primaryName;
  }, [props.currentTitle, props.primaryNavigation.items, props.secondaryNavigation?.items]);

  return (
    <>
      <div className="sticky top-0 z-50 flex items-center gap-x-6 bg-white dark:bg-gray-900 px-4 h-14 shadow-sm sm:px-6 lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 dark:text-gray-300 lg:hidden"
          onClick={() => setSidebarOpen(true)}
          data-role="sidebar-toggle-mobile"
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900 dark:text-white truncate">
          {currentPageName}
        </div>
        <Dropdown
          links={[
            { name: "Account settings", to: ["up.settings.personal"] },
            { name: "New team", onClick: () => startCreateTeamFlow(addSlideOver) },
            { name: "Support", onClick: getSupport },
            { name: "Sign out", onClick: props.onLogout, className: "text-red-700" },
          ]}
        >
          <>
            <span className="sr-only">Your profile</span>
            <UserCircleIcon className="h-6 w-6 text-slate-500 dark:text-gray-400 cursor-pointer" />
          </>
        </Dropdown>
      </div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-60 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white dark:bg-gray-900 px-6 pb-2">
                  <ProductSwitcher product={props.product} switchOptions={props.availableProducts} />
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          <NavigationItems items={props.primaryNavigation.items} />
                        </ul>
                      </li>
                      {props.secondaryNavigation && (
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            {props.secondaryNavigation.title}
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            <NavigationItems items={props.secondaryNavigation.items} />
                          </ul>
                        </li>
                      )}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

function DesktopNavigation(
  props: Props & { desktopSideBarOpen: boolean; setDesktopSidebarOpen: (open: boolean) => void },
) {
  const { hiddenSidebar, setDesktopSidebarOpen } = props;
  function openSidebar(evt: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    evt.preventDefault();
    setDesktopSidebarOpen(true);
  }

  useEffect(() => {
    if (hiddenSidebar) setDesktopSidebarOpen(false);
  }, [hiddenSidebar, setDesktopSidebarOpen]);

  return (
    <>
      <Transition.Root show={!props.hiddenSidebar} as={Fragment} key="visible-sidebar">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leaveFrom="translate-x-0"
          leaveTo="!-translate-x-full"
        >
          <DesktopSidebar {...props} />
        </Transition.Child>
      </Transition.Root>
      {props.hiddenSidebar && (
        <>
          <Transition.Root show={props.desktopSideBarOpen} as={Fragment} key="hidden-sidebar--sidebar">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="!-translate-x-full"
            >
              <Dialog as={Fragment} onClose={props.setDesktopSidebarOpen}>
                <DesktopSidebar {...props} />
              </Dialog>
            </Transition.Child>
          </Transition.Root>
          <Transition.Root show={props.desktopSideBarOpen} as={Fragment} key="hidden-sidebar--toggle">
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/90 z-50" />
            </Transition.Child>
          </Transition.Root>
        </>
      )}
      <a
        className={classNames(
          `hidden lg:flex fixed inset-y-0 w-10 items-start justify-center bg-gray-50 border-r border-gray-200 dark:border-gray-800
          dark:bg-gray-900 cursor-pointer pt-4 transition-all ease-linear duration-300`,
          props.topBar && "mt-12",
          !props.hiddenSidebar && "opacity-0",
        )}
        onClick={openSidebar}
        data-role="sidebar-toggle-desktop"
      >
        <span className="sr-only">Open sidebar</span>
        <LogoSquare height={24} className="text-slate-700 dark:text-white/70" />
      </a>
    </>
  );
}

const DesktopSidebar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { addSlideOver } = useSlideOver();

  return (
    <div
      className={classNames(
        "hidden fixed inset-y-0 z-50 lg:flex w-72 flex-col overflow-y-auto",
        props.topBar && "mt-12",
        props.hiddenSidebar && "dark:bg-slate-900 -translate-x-full",
      )}
      ref={ref}
    >
      <div className="flex grow flex-col gap-y-5 border-r border-gray-200 bg-white dark:border-gray-800 dark:bg-black/20 px-6 cursor-default">
        <ProductSwitcher product={props.product} switchOptions={props.availableProducts} />
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" className="-mx-2 space-y-1">
                <NavigationItems items={props.primaryNavigation.items} />
              </ul>
            </li>
            {props.secondaryNavigation && (
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">{props.secondaryNavigation.title}</div>
                <ul role="list" className="-mx-2 mt-2 space-y-1">
                  <NavigationItems items={props.secondaryNavigation.items} />
                </ul>
              </li>
            )}
            <li className="mt-auto shrink-0">
              <Gamification currentProduct={props.product} game={props.gamification} />

              <div className="flex justify-between items-center mb-3">
                <div className="w-full">
                  <p className="text-sm text-gray-500 dark:text-gray-400">Signed in as</p>
                  <p className="truncate text-sm text-gray-900 dark:text-white">{props.auth.email}</p>
                </div>
                <Dropdown
                  links={[
                    { name: "Account settings", to: ["up.settings.personal"] },
                    { name: "New team", onClick: () => startCreateTeamFlow(addSlideOver) },
                    { name: "Support", onClick: getSupport },
                    { name: "Sign out", onClick: props.onLogout, color: "red" },
                  ]}
                >
                  <div className="h-6 w-6 rounded-full text-slate-500 hover:text-slate-900 dark:hover:text-slate-200 cursor-pointer">
                    <EllipsisVerticalIcon />
                  </div>
                </Dropdown>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
});

function NavigationItems({ items }: { items: NavItem[] }) {
  return (
    <>
      {items.map((item) => (
        <li key={item.name}>
          <Link
            to={item.to}
            className={classNames(
              item.current
                ? "bg-primary-100 dark:bg-gray-800 text-primary-600 dark:text-gray-200"
                : `text-gray-700 dark:text-gray-100 hover:text-primary-600 hover:bg-primary-100 dark:hover:text-gray-200
                  dark:hover:bg-gray-800`,
              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold truncate active:scale-95 transition-transform",
            )}
          >
            {item.icon ? (
              <item.icon
                className={classNames(
                  item.current
                    ? "text-primary-600 dark:text-gray-200"
                    : "text-gray-400 dark:text-gray-400 group-hover:text-primary-600 dark:group-hover:text-gray-200",
                  "h-6 w-6 shrink-0",
                )}
                aria-hidden="true"
              />
            ) : (
              <span
                className={classNames(
                  item.current
                    ? "text-primary-600 border-primary-600 dark:text-white dark:border-gray-700 dark:bg-gray-800"
                    : `text-gray-400 border-gray-200 dark:border-gray-600 group-hover:border-primary-600 group-hover:text-primary-600
                      dark:group-hover:border-gray-700 dark:group-hover:text-white`,
                  `flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-semibold bg-white
                  dark:bg-gray-900`,
                )}
              >
                {item.name[0]?.toUpperCase()}
              </span>
            )}
            {item.name}
            {typeof item.count === "number" ? (
              <span
                className={classNames(
                  "ml-auto w-9 min-w-max whitespace-nowrap rounded-full px-2.5 py-0.5 text-center text-xs leading-5 border",
                  item.countColor === "red"
                    ? "bg-red-100 dark:bg-red-700 text-red-800 dark:text-red-100 border-red-700/50"
                    : "bg-gray-100 dark:bg-gray-600 text-gray-600 dark:text-gray-100 border-gray-700/50",
                )}
                aria-hidden="true"
              >
                {item.count}
              </span>
            ) : null}
          </Link>
        </li>
      ))}
    </>
  );
}

function productRoot(product: Product | undefined): LinkToParam {
  return product
    ? {
        userview: ["uv.root"] as LinkToParam,
        helloscreen: ["hs.root"] as LinkToParam,
        cobrowsingapi: ["co.root"] as LinkToParam,
        livedocument: ["ld.root"] as LinkToParam,
      }[product]
    : ["up.root"];
}

function ProductSwitcher({ product, switchOptions }: { product?: Product; switchOptions?: Set<Product> }) {
  if (!switchOptions || switchOptions.size === 0) {
    return (
      <div className="flex shrink-0 mt-3">
        <Link to={productRoot(product)}>
          <ProductLogo product={product} className="text-black dark:text-white" />
        </Link>
      </div>
    );
  }

  const options = Array.from(switchOptions).map((p) => ({
    name: productName(p),
    to: productRoot(p),
  }));

  return (
    <div className="flex shrink-0 mt-3 items-center justify-between">
      <Link to={productRoot(product)}>
        <ProductLogo product={product} className="text-black dark:text-white" />
      </Link>
      <Dropdown links={options}>
        <div className="h-6 w-6 rounded-full text-slate-500 hover:text-slate-900 dark:hover:text-slate-200 cursor-pointer">
          <Squares2X2Icon />
        </div>
      </Dropdown>
    </div>
  );
}

function getSupport() {
  if (typeof (window as any).Intercom === "function") return (window as any).Intercom("showNewMessage");

  window.open("mailto:support@upscope.com");
}
