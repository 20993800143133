import { createRoot } from "react-dom/client.js";
import Cookie from "js-cookie";
import App from "./app";
import { initI18n } from "./i18n";
import * as Sentry from "@sentry/browser";

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://5657ddafdf314975895cc564eb17315f@sentry.io/157375",
    release: process.env.CURRENT_APP_VERSION,
    environment: process.env.NODE_ENV,
    maxValueLength: 2500,
  });
}

const language = Cookie.get("upscope_locale");
initI18n(language);

const root = createRoot(document.querySelector("#root")!);

root.render(<App />);
