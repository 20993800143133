import { useLocation, useParams } from "react-router-dom";
import { useEffect } from "react";
import Plausible from "plausible-tracker";
import useCachedObject from "../use/cached-object";

const plausible =
  process.env.NODE_ENV === "production"
    ? Plausible({
        domain: "app.upscope.io,all-upscope.com",
        apiHost: "https://data.upscope.com",
      })
    : undefined;

export function LogRoute({ children }: { children: React.ReactNode }) {
  const detectedParams = useParams();
  const { pathname } = useLocation();

  const cachedParams = useCachedObject(detectedParams);

  useEffect(() => {
    let route = `/${routePrefix(location.hostname)}:${pathname}`;
    Object.entries(cachedParams).forEach(([key, value]) => {
      if (value) {
        route = route.replace(value, `:${key}`);
      }
    });
    trackPageview(route);
  }, [pathname, cachedParams]);

  return children;
}

function routePrefix(hostname: string): string {
  if (hostname.startsWith("hq.")) {
    return "hq";
  }
  if (hostname.includes("helloscreen")) {
    return "hs";
  }
  if (hostname.includes("userview")) {
    return "uv";
  }
  if (hostname.includes("livedocument")) {
    return "ld";
  }
  if (hostname.includes("cobrowsingapi")) {
    return "co";
  }

  return "up";
}

function trackPageview(route: string) {
  console.log("Tracking pageview:", route);
  plausible?.trackPageview({
    url: location.origin + route + location.search + location.hash,
  });
}

export function trackEvent(eventName: string, props: Record<string, any>) {
  console.log("Tracking event:", eventName);
  plausible?.trackEvent(eventName, { props });
}
