import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIdle } from "react-use";
import { UpscopeProvider } from "@upscopeio/react";

import api from "../api-client";
import LoadingSpinner from "../components/ui/LoadingSpinner";
import { path } from "../paths";
import { useCachedState } from "../use/cached-object";
import { presence } from "../utils";
import { useGoogleTagManager } from "../vendor/use-google-tag-manager";
import useIntercom from "../vendor/use-intercom";

import DataTypes from "~data";
import {
  AuthStatusShowResponse,
  TeamAuthStatusData,
  TeamMembershipAuthStatusData,
  UserAuthStatusData,
} from "~services/app/endpoints/auth/statusShow/response";

type AdminRole = Exclude<DataTypes["Users"]["adminRoles"], null>[number];
type PersonalLink = Exclude<AuthStatusShowResponse["auth"], null>["personalLink"];

type ProviderProps = {
  children: JSX.Element;
};

const AuthenticationContext = createContext({
  user: null as UserAuthStatusData | null,
  personalLink: null as PersonalLink,
  teams: null as Array<TeamAuthStatusData> | null,
  teamMemberships: null as Array<TeamMembershipAuthStatusData & { team: TeamAuthStatusData }> | null,
  impersonating: null as boolean | null,
  promptForPassword: false,
  adminRoles: [] as AdminRole[],
  refresh: async () => {},
  logout: async () => {},
});

export function useAuthentication() {
  return useContext(AuthenticationContext);
}

export default function AuthenticationProvider({ children }: ProviderProps) {
  const [fetched, setFetched] = useState(false);
  const [promptForPassword, setPromptForPassword] = useState(false);
  const [personalLink, setPersonalLink] = useState<PersonalLink>(null);
  const [user, setUser] = useCachedState<UserAuthStatusData | null>(null);
  const [teams, setTeams] = useCachedState<Array<TeamAuthStatusData> | null>(null);
  const [impersonating, setImpersonating] = useState<boolean | null>(null);
  const [teamMemberships, setTeamMemberships] = useCachedState<TeamMembershipAuthStatusData[] | null>(null);
  const [csCompanyId, setCsCompanyId] = useState<string | null>(null);
  const [adminRoles, setAdminRoles] = useState<AdminRole[]>([]);
  const [baa, setBaa] = useState<boolean | null>(null);
  const isIdle = useIdle(60_000);
  useIntercom({
    enable: fetched && !impersonating && !user?.isAdmin,
    userId: user?.id,
    hashedId: presence(user?.intercomId),
  });
  useGoogleTagManager({
    userId: user?.id,
    companyId: csCompanyId,
    baa: baa,
  });

  const refresh = useCallback(() => {
    return api("auth.status.show").then((response) => {
      setFetched(true);
      if (response.auth) {
        setUser(response.auth.user);
        setTeams(response.auth.teams);
        setImpersonating(response.auth.impersonating);
        setTeamMemberships(response.auth.teamMemberships);
        setPersonalLink(response.auth.personalLink);
        setPromptForPassword(response.auth.promptForPassword);
        setCsCompanyId(response.auth.csCompanyId);
        setBaa(response.auth.baa);
        setAdminRoles(response.auth.adminRoles);
      }
    });
  }, [setTeamMemberships, setTeams, setUser]);

  const teamMembershipsWithTeam: Array<TeamMembershipAuthStatusData & { team: TeamAuthStatusData }> | null =
    useMemo(() => {
      return (
        teamMemberships?.map((membership: TeamMembershipAuthStatusData) => {
          const m = { ...membership } as TeamMembershipAuthStatusData & { team: TeamAuthStatusData };
          m.team = teams!.find((t) => t.id === membership.teamId)!;
          return m;
        }) || null
      );
    }, [teams, teamMemberships]);

  const logout = useCallback(() => {
    return api("auth.logout").then(() => {
      setUser(null);
      setTeams(null);
      setTeamMemberships(null);
      location.href = path("up.root"); // Cannot  use navigate because this happens outside of the router
    });
  }, [setTeamMemberships, setTeams, setUser]);

  useEffect(() => {
    if (isIdle) return;

    refresh();
  }, [refresh, isIdle]);

  if (!fetched) return <LoadingSpinner />;

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        teams,
        impersonating,
        refresh,
        teamMemberships: teamMembershipsWithTeam,
        logout,
        promptForPassword,
        adminRoles,
        personalLink,
      }}
    >
      <UpscopeProvider
        apiKey="N1ZaRHmZcJbuJdMK6TmyF8My"
        enabled={process.env.NODE_ENV === "production" && !impersonating && !user?.isAdmin}
        uniqueId={user?.id}
        identities={[user?.name, user?.email, teams?.[0]?.domain].filter(Boolean)}
      >
        {children}
      </UpscopeProvider>
    </AuthenticationContext.Provider>
  );
}
