import React from "react";
import { useLocation } from "react-router-dom";

import ApplicationLayoutProvider from "./layouts/ApplicationLayoutProvider";
import AuthLayoutProvider from "./layouts/AuthLayoutProvider";
import { useAuthentication } from "./AuthenticationProvider";

export default function LayoutProvider({ children }: { children: React.ReactNode }) {
  const { pathname } = useLocation();
  const { user } = useAuthentication();

  const noLayout =
    pathname === "/waitlist" || // Do not show on livedocument waitlist
    pathname.startsWith("/extension") || // Do not show any layout for extension pages
    pathname === "/auth/tokens/new" || // Do not show any layout for the personal auth token page
    pathname.split("/")[1] === "embed"; // Do not show any layout when the app is embedded

  if (noLayout) return <div className="flex flex-col min-h-screen">{children}</div>;

  if (user) return <ApplicationLayoutProvider>{children}</ApplicationLayoutProvider>;

  // Allow access to the installation page without authentication
  if (pathname.startsWith("/welcome") || location.hostname.includes("livedocument")) return children;

  return <AuthLayoutProvider>{children}</AuthLayoutProvider>;
}
