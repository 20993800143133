import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useModal } from "../../../providers/ModalProvider";
import t from "../../../i18n";
import FormInput from "../../forms/FormInput";
import Input from "../../forms/Input";
import api from "../../../api-client";
import { useSessionStorageValue } from "@react-hookz/web";

export default function PasswordModal() {
  const { value: doNotOffer, set: setDoNotOffer } = useSessionStorageValue("doNotOfferPassword", {
    defaultValue: false,
  });
  const { addModal, removeModal } = useModal();
  const contentRef = useRef<ModalContentRef>(null);
  const doNotOfferRef = useRef(doNotOffer);
  doNotOfferRef.current = doNotOffer;
  const setDoNotOfferRef = useRef(setDoNotOffer);
  setDoNotOfferRef.current = setDoNotOffer;

  useEffect(() => {
    if (doNotOfferRef.current) {
      return;
    }

    addModal({
      title: t("Set a password"),
      cancelText: t("Not now"),
      confirmText: t("Set password"),
      content: <PasswordModalContent ref={contentRef} remove={removeModal} />,
      onClick: async (setPassword: boolean) => {
        if (!setPassword) {
          setDoNotOfferRef.current(true);
          return true;
        }
        return contentRef.current?.submit() ?? true;
      },
    });
  }, [removeModal, addModal]);

  return null;
}

export type ModalContentRef = {
  submit: () => Promise<boolean>;
};

export type ModalContentProps = {
  remove: () => void;
};

const PasswordModalContent = forwardRef<ModalContentRef, ModalContentProps>(function (props, ref) {
  const onSubmit = useCallback(
    async (evt?: React.FormEvent) => {
      evt?.preventDefault();
      const response = await api("auth.password.set", {
        user: {
          password: inputRef.current?.value ?? "",
        },
      });

      if (response.success) {
        if (evt) {
          props.remove();
        }

        addModal({
          type: "success",
          title: t("Password set"),
          content: t("Your password has been set. You can now log in with your email and password."),
          replace: true,
        });
        return true;
      } else if (response.error === "password_not_needed") {
        return true;
      } else if (response.error === "password_missing") {
        setError(t("Please enter a password."));
        return false;
      } else {
        setError(response.userErrors.password);
        return false;
      }
    },
    [props.remove],
  );

  useImperativeHandle(
    ref,
    () => {
      return {
        submit() {
          return onSubmit();
        },
      };
    },
    [onSubmit],
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | undefined>();
  const { addModal } = useModal();

  useEffect(() => {
    setTimeout(() => {
      // Wait for modal to open
      inputRef.current?.focus();
    }, 100);
  }, [inputRef]);

  return (
    <form onSubmit={onSubmit}>
      <p>{t("Setting a password allows you to log into Upscope more quickly and securely.")}</p>
      <FormInput
        label={t("Password")}
        explanation={t("Make sure it is long and secure.")}
        className="text-left !mt-5 !mb-0"
        error={error}
      >
        <Input type="password" ref={inputRef} required autoComplete="new-password" />
      </FormInput>
      <button type="submit" className="hidden">
        Submit
      </button>{" "}
      {/* for enter key to submit form */}
    </form>
  );
});
