import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description?: string;
  children: React.ReactNode;
};

export default function SlideOver(props: Props) {
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={props.setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel
                  data-role="slideover"
                  className="pointer-events-auto w-screen max-w-md flex flex-col h-full"
                >
                  <div className="bg-primary-700 dark:bg-black px-4 py-6 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-base font-semibold leading-6 text-white">
                        {props.title}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md text-primary-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => props.setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    {props.description && (
                      <div className="mt-1">
                        <p className="text-sm text-primary-300 dark:text-white/80">{props.description}</p>
                      </div>
                    )}
                  </div>
                  {props.children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

SlideOver.Footer = function SlideOverFooter(props: { children: React.ReactNode }) {
  return <div className="flex flex-shrink-0 justify-between -px-4 pt-4">{props.children}</div>;
};

SlideOver.Content = function SlideOverContent<
  T extends keyof JSX.IntrinsicElements | React.JSXElementConstructor<any> = "div",
>({
  children,
  as: asProp,
  ...rest
}: { children: React.ReactNode; as?: T } & (T extends keyof JSX.IntrinsicElements
  ? JSX.IntrinsicElements[T]
  : React.ComponentProps<T>)) {
  const As = asProp || ("div" as T);
  return (
    <As
      className="flex-1 flex flex-col justify-between divide-y divide-neutral-200 dark:divide-neutral-800 bg-white dark:bg-neutral-900 dark:text-white shadow-xl p-4 overflow-auto"
      {...rest}
    >
      {children}
    </As>
  );
};
