import { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/browser";

import ApiCacheProvider, { useApiCache } from "./providers/ApiCacheProvider";
import AuthenticationProvider from "./providers/AuthenticationProvider";
import DarkModeProvider from "./providers/DarkModeProvider";
import { ModalProvider, useModal } from "./providers/ModalProvider";
import { NotificationsProvider } from "./providers/NotificationsProvider";
import { PreloadProvider } from "./providers/PreloadProvider";
import { StripeProvider } from "./providers/StripeProvider";
import t from "./i18n";
import Router from "./router";

export default function App() {
  return (
    <ApiCacheProvider>
      <PreloadProvider>
        <DarkModeProvider>
          <NotificationsProvider>
            <ModalProvider>
              <ErrorBoundary FallbackComponent={FatalError}>
                <AuthenticationProvider>
                  <StripeProvider>
                    <Router />
                  </StripeProvider>
                </AuthenticationProvider>
              </ErrorBoundary>
            </ModalProvider>
          </NotificationsProvider>
        </DarkModeProvider>
      </PreloadProvider>
    </ApiCacheProvider>
  );
}

export function FatalError({ error }: { error: unknown }) {
  const { addModal } = useModal();
  const { newVersionAvailable } = useApiCache();

  useEffect(() => {
    if (typeof error === "undefined" || error === null) {
      return;
    }
    if (newVersionAvailable) {
      return;
    }

    if (error instanceof Error) {
      // This happens when a new version of the app is deployed and we try to
      // load a script with an invalid hash
      if (error.message.match(/to fetch dynamically imported module/)) {
        return location.reload();
      }
    }
    Sentry.captureException(error);
  }, [error, newVersionAvailable]);

  useEffect(() => {
    if (newVersionAvailable) {
      addModal({
        title: t("A new version is available"),
        content: t("A new version of the application is available and we need to reload the page for it to work."),
        onClick: () => location.reload(),
        confirmText: t("Reload application"),
      });
    } else {
      addModal({
        title: t("You just found a bug!"),
        content: t("It looks like you found a bug with our system. Our team has been alerted."),
        onClick: () => location.reload(),
        confirmText: t("Try again"),
      });
    }
  }, [addModal, newVersionAvailable]);

  return null;
}
